import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeekMon, endOfWeekSun, tomorrow, shiftWeek, shiftHours, undoDateFormat, dateFormat, textfieldDateFormat } from "../../utils/dateFunctions";
import { Locations } from "../../utils/constants/locations";
import { getOysterGrades, gradeSort } from "../../definitions/oysters";
import DateSelector from "../../shared/DateSelectorMultiWeek";
import WarehouseSelector from "../../components/WarehouseSelector";
import PurchaseOrders from '../Procurement/Tables/PurchaseOrders';
import POSummaryPoints from '../Procurement/Stats/POSummaryPoints';
import SelectAOCFarmWarehouse from 'app/components/SelectAOCFarmWarehouse';
import { getAPIDataParamsReturn, getAPIDataReturn } from "../../utils/apiFunction";
import { KEYWAREHOUSESTRINGS } from '../../utils/constants/warehouse'
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { numberFormat, numberFormatwithNeg } from "../../utils/numberFunctions";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from '@mui/material';


const ProcurementDashboard = (props) => {
    var noWeeks = 4;
    const [startDate, setStartDate] = React.useState(shiftHours(startOfWeekMon(new Date()), -7 * 24));
    const [endDate, setEndDate] = React.useState(shiftHours(endOfWeekSun(new Date()), (noWeeks - 1) * 7 * 24));
    const startDateMemo = React.useMemo(() => (startDate), [startDate]);
    const endDateMemo = React.useMemo(() => (tomorrow(endDate)), [endDate]);
    const [location, setLocation] = React.useState(0);
    const [exReload, setExReload] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);
    const [supplier, setSupplier] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [inUse, setInUse] = React.useState(false);
    const [grades, setGrades] = React.useState([]);
    const [completedProcessRuns, setCompletedProcessRuns] = React.useState(null);
    let timer;
    const reloadFunc = () => {
        setReload(!reload);
    }
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);
    React.useEffect(() => {
        setLoading(true);
        setCompletedProcessRuns(null);
        if (supplier) {
            getAPIDataParamsReturn('/Processing/GetProcessRunsBySupplier', { supplierId: supplier ? supplier.id : 0, startDate: textfieldDateFormat(startDate), endDate: textfieldDateFormat(endDate), WarehouseId: 7 }).then(async (dataAll) => {
                var data = dataAll.filter((item) => !item.stockItem?.isProcessed);
                for (var i = 0; i < data.length; i++) {
                    if (data[i].coreInventory.allReceivals.length > 0) {
                        //check the contents of allreceivals equal each other
                        var allReceivals = data[i].coreInventory.allReceivals;
                        var missingReceivals = false;
                        for (var j = 0; j < allReceivals.length; j++) {
                            for (var k = j + 1; k < allReceivals.length; k++) {
                                if (allReceivals[j] !== allReceivals[k]) {
                                    missingReceivals = true;
                                }
                            }
                        }
                        if (missingReceivals) {
                            var recPrmArr = [];
                            for (var j = 0; j < allReceivals.length; j++) {
                                recPrmArr.push(getAPIDataReturn('/Receivals/Get/' + allReceivals[j]))
                            }
                            await Promise.all(recPrmArr).then((values) => {

                                data[i].coreInventory.receivalsList = values;
                            })
                        }
                    }
                }
                setLoading(false);

                setCompletedProcessRuns(data);
            });
        }
    }, [supplier, startDate, endDate, reload]);


    const nextWeekfunc = () => {
        var newDates = shiftWeek(startDate, 1);
        setStartDate(newDates.start);
        setEndDate(newDates.end);
    }
    const lastWeekfunc = () => {
        var newDates = shiftWeek(startDate, -1);
        setStartDate(newDates.start);
        setEndDate(newDates.end);
    }
    const supplierFilter = (data) => {
        var newData = [...data];
        if (supplier) {
            newData = data.filter((item) => item.supplierId === supplier.id);
        } else {
            return [];
        }
        var usedPRs = [];
        var sortedPRs = completedProcessRuns?completedProcessRuns.sort((a, b) => b.quantity - a.quantity):[];
        for (var i = 0; i < newData.length; i++) {
            var relevantPRs = sortedPRs.filter((item) => {
                if (item?.coreInventory?.receivalsList) {
                    if (item?.coreInventory?.receivalsList.find((rec) => rec?.purchaseOrderId == newData[i].id)) {
                        return true;
                    }
                } else {
                    return (item?.coreInventory?.receival?.purchaseOrderId == newData[i].id);
                }
            });
            var savedPRs = [];
            //organise daata because there can be two otrs for the same lsn and stock item
            for (var j = 0; j < newData[i].purchaseOrderLines.length; j++) {
                var quantityOfLine = newData[i].purchaseOrderLines[j].ordered;
                for (var k = 0; k < relevantPRs.length; k++) {
                    if (!(usedPRs.includes(relevantPRs[k].id))) {
                        if (relevantPRs[k].stockItemId === newData[i].purchaseOrderLines[j].stockItemId) {
                            if (relevantPRs[k].quantity <= quantityOfLine) {
                                quantityOfLine -= relevantPRs[k].quantity;
                                savedPRs.push(relevantPRs[k]);
                                usedPRs.push(relevantPRs[k].id);
                            }
                        }
                    }
                }
            }
            var unUsedPRs = relevantPRs.filter((item) => !usedPRs.includes(item.id));
            //group into grades
            var gradeTotals = {};
            for (var k = 0; k < savedPRs.length; k++) {
                var pr = savedPRs[k];
                if (!gradeTotals[pr.stockItem?.gradeId]) {
                    gradeTotals[pr.stockItem?.gradeId] = {};
                }
                for (var j = 0; j < pr.processRunOutputs.length; j++) {
                    var pro = pr.processRunOutputs[j];
                    // console.log(pro);
                    if (!gradeTotals[pr.stockItem?.gradeId][pro.stockItem?.gradeId]) {
                        gradeTotals[pr.stockItem?.gradeId][pro.stockItem?.gradeId] = 0;
                    }
                    gradeTotals[pr.stockItem?.gradeId][pro.stockItem?.gradeId] += (pro.quantity ? pro.quantity : 0);
                }
            }
            newData[i].processRuns = gradeTotals;
        }
        var cpyNewData = [...newData];
        var j = 0;
        var gradeTotals = {};
        for (var i = 0; i < cpyNewData.length; i++) {
            //insert a line into new data at the end of each week
            //sum all the grades for the week



            if (i === 0) {

            } else {
                if ((startOfWeekMon(undoDateFormat(cpyNewData[i].orderDate)) > startOfWeekMon(undoDateFormat(cpyNewData[i - 1].orderDate)))) {
                    var sumOfAllGradeTotals = 0;
                    for (var grade of grades) {
                        if (!gradeTotals[grade.abv]) {
                            gradeTotals[grade.abv] = 0;
                        }
                        sumOfAllGradeTotals += gradeTotals[grade.abv];
                    }

                    newData.splice(j, 0, { myobid: 'Week Total', orderDate: dateFormat(endOfWeekSun(undoDateFormat(cpyNewData[i - 1].orderDate))), comments: 'Sum Total', promisedDate: sumOfAllGradeTotals, ...gradeTotals });
                    j++;

                    gradeTotals = {};
                }
            }
            j++;
            for (var grade of grades) {
                if (!gradeTotals[grade.abv]) {
                    gradeTotals[grade.abv] = 0;
                }
                gradeTotals[grade.abv] += (cpyNewData[i][grade.abv] ? cpyNewData[i][grade.abv] : 0);
            }
            if (i === cpyNewData.length - 1) {
                var sumOfAllGradeTotals = 0;
                for (var grade of grades) {
                    if (!gradeTotals[grade.abv]) {
                        gradeTotals[grade.abv] = 0;
                    }
                    sumOfAllGradeTotals += gradeTotals[grade.abv];
                }
                newData.splice(j, 0, { myobid: 'Week Total', orderDate: dateFormat(endOfWeekSun(undoDateFormat(cpyNewData[i]?.orderDate))), comments: 'Sum Total', promisedDate: sumOfAllGradeTotals, ...gradeTotals });
                j++;

                gradeTotals = {};
            }
        }
        return newData;
    }

    return (<div>
        <Grid container spacing={2} mb={1} >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                <DateSelector montosun startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} noWeeks={noWeeks} />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
                {/* <WarehouseSelector location={location} setLocation={setLocation} />
                 */}
                <SelectAOCFarmWarehouse showAll normal sortToTop setState={setSupplier} state={supplier} access={props.access} disabled={completed} title="Farm" />

            </Grid>
        </Grid>
        <Grid container spacing={3.5}>
            {/*<Grid item xs={12}>*/}
            {/*    <Summary oldInfo location={location} startDate={startDateMemo} endDate={(endDateMemo)} externalReload={exReload} />*/}
            {/*</Grid>*/}

            <Grid item xs={12}>
                <PurchaseOrders location={location} showQA oldInfo long totals height="3000px" nextWeek={nextWeekfunc} lastWeek={lastWeekfunc} filter={supplierFilter} warehouseFilter={Locations[location] ? Locations[location] : null} startDate={startDateMemo} endDate={(endDateMemo)} externalReload={exReload} shrinkTable reverseSort showOutcomes showWeekBrackets processRunsLoading={loading} exReloadFunc={reloadFunc} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant={"h2"} >Processing Outcomes</Typography>
            </Grid>
            {!loading ? <Grid item xs={12}>
                <ProcessingSummaries processRuns={completedProcessRuns} grades={grades} />
            </Grid> : <CircularProgress />}
        </Grid>
    </div >);

};

const ProcessingSummaries = (props) => {
    var gradeTotals = {};
    for (var i = 0; i < props.grades?.length; i++) {
        gradeTotals[props.grades[i].id] = { input: 0, output: {} };
        for (var j = 0; j < props.processRuns?.length; j++) {
            if (props.processRuns[j].stockItem.gradeId === props.grades[i].id) {
                gradeTotals[props.grades[i].id].input += props.processRuns[j].quantity;

                for (var k = 0; k < props.processRuns[j].processRunOutputs.length; k++) {
                    var outGradeId = props.processRuns[j].processRunOutputs[k].stockItem.gradeId;
                    if (!gradeTotals[props.grades[i].id].output[outGradeId]) {
                        gradeTotals[props.grades[i].id].output[outGradeId] = 0;
                    }
                    gradeTotals[props.grades[i].id].output[outGradeId] += props.processRuns[j].processRunOutputs[k].quantity;
                }
            }
        }
    }

    var gradesTotalsObjectKeys = Object.keys(gradeTotals).sort((a,b)=>(props.grades.find((g)=>(g.id===Number(a)))?.order-props.grades.find((g)=>(g.id===Number(b)))?.order));
    return <Grid container spacing={3.5}>
        {gradesTotalsObjectKeys.map((gradeId) => {
            if (gradeTotals[gradeId]?.input > 0) {
                return <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={3}>
                    <StatCard title={props.grades?.find((g) => g.id === Number(gradeId))?.Species + ' ' + props.grades?.find((g) => g.id === Number(gradeId))?.Grade} value={(gradeTotals[gradeId]?.input)} gradeTotal={gradeTotals[gradeId]?.output} grades={props.grades} />
                </Grid>
            } else {
                return null
            }
        })}
    </Grid>
}



export default ProcurementDashboard;



const StatCard = (props) => {
    var bgroundStyle = {};
    if (props.color) {
        bgroundStyle = {
            background: props.color,
        }
    }
    var totalOut = 0;
    for (var key in props.gradeTotal) {
        totalOut += props.gradeTotal[key];
    }

    return <Card sx={{ position: "relative", ...bgroundStyle }} >
        <CardHeader
            title={<React.Fragment><Typography style={{}} variant={"h5"} mb={0}>{props.title} </Typography><Typography style={{ right: '10px', top: '15px', position: 'absolute' }} variant={"h2"}>{numberFormat(props.value)} doz</Typography></React.Fragment>}
            subheader={''}
            action={''}
            avatar={''}
            sx={{
                zIndex: 2,
                position: "relative",
                //...headerSx
            }}
        />
        <Div sx={{
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingBottom: '24px',
        }}>
            <Typography variant={"h6"} sx={{ textDecoration: 'underline' }}>Outputs</Typography>
            {props.grades.map((grade) => {
                if (props.gradeTotal[grade.id] > 0) {
                    return <Grid container spacing={3.5}>
                        <Grid item xs={7}>
                            <Typography  >{grade.Species + ' ' + grade.Grade}</Typography>
                        </Grid>
                        <Grid item xs={5} style={{textAlign: 'right'}}>
                            <Typography>{numberFormat(props.gradeTotal[grade.id]) + ' (' + (props.gradeTotal[grade.id] / totalOut * 100).toFixed(0) + '%)'}</Typography>
                        </Grid>
                    </Grid>
                } else {
                    return null;
                }
            })}
        </Div>
        <Div sx={{
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingBottom: '24px',
        }}>
            <Grid container spacing={3.5}>
            <Grid item xs={7}>
            <Typography variant={"h4"} sx={{}}>Total Out</Typography>
            <Typography variant={"h4"} sx={{}}>Variance</Typography>
            </Grid>
            <Grid item xs={5} style={{textAlign: 'right'}}>
            <Typography variant={"h4"} sx={{}}>{numberFormat(totalOut)} doz</Typography>
            <Typography variant={"h4"} sx={{}}>{numberFormatwithNeg(totalOut-props.value)} ({((totalOut-props.value)/props.value*100).toFixed(0)}%)</Typography>
            </Grid>
            </Grid>
            </Div>
    </Card>
}